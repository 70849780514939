<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14 nav-selected">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div
        class="finish-smail d-flex justify-content-center align-items-center column"
      >
        <div class="mt-16 fm-18">신청이 완료 되셨습니다!</div>
        <div class="text-center fr-14 f-g70 mt-16 mb-40">
          신청승인은 주말 및 공휴일을 제외하여 1일 정도 소요되며<br />
          신청완료 후 기재해주신 연락처 및 이메일로 승인여부를 알려드립니다.
        </div>
      </div>
      <div class="contentBox">
        <div class="title_name">
          <span class="fm-16">가입완료</span>
          <p class="fr-12 mt-8">가입하신 회원님의 정보입니다.</p>
        </div>
        <ul class="my_information">
          <li class="py-16 d-flex">
            <span class="finish-title">이메일</span>
            <span class="finish-value">{{ email }}</span>
          </li>
          <li class="py-16 d-flex">
            <span class="finish-title">연락처</span>
            <span class="finish-value">{{ formatPhone(phone) }}</span>
          </li>
          <li class="py-16 d-flex">
            <span class="finish-title">이름</span>
            <span class="finish-value">{{ name }}</span>
          </li>
          <li class="py-16 d-flex">
            <span class="finish-title">생년월일</span>
            <span class="finish-value">{{ birth }}</span>
          </li>
          <li class="py-16 d-flex">
            <span class="finish-title">성별</span>
            <span class="finish-value">{{ gender }}</span>
          </li>
          <li class="py-16 d-flex">
            <span class="finish-title">주소</span>
            <span class="finish-value">{{ addr }} {{ addr2 }}</span>
          </li>
          <li class="py-16 d-flex">
            <span class="finish-title">전문분야</span>
            <span class="finish-value"
              >{{ highSpecialty }} > {{ lowSpecialty }} >
              {{ orderSpecialty }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-center mb-80" style="margin-top: 62px">
      <div>
        <p class="finish-guide">
          가입승인을 기다리는 동안 매달의 파트너관리자의 이용방법을 알아볼까요?
        </p>
        <div class="w-100 flex-justify-center">
          <button-common
            :size="SIZE_S"
            :clr="CLR_G"
            style="width: 240px"
            @click="
              clickUrl(
                'https://hyunwoosong.notion.site/1d5f7bff02f848a396f60b5c92c9d7f6'
              )
            "
            >매달 파트너관리자 가이드 보기</button-common
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Finish",
  components: {},
  data() {
    return {
      email: "",
      phone: "",
      name: "",
      birth: "",
      gender: "",
      addr: "",
      addr2: "",
      lowSpecialty: "",
      highSpecialty: "",
      orderSpecialty: "",
    };
  },
  async mounted() {
    await this.getRegInfo();
  },
  methods: {
    async getRegInfo() {
      const rs = await this.$axios({
        url: "/partner/reg/finish",
        params: {},
      });
      if (rs.result === "SUC") {
        this.setData(rs);
      }
    },
    setData(data) {
      this.email = data.email ?? "";
      this.phone = data.phone ?? "";
      this.name = data.name ?? "";
      this.birth = data.birth ?? "";
      this.gender = data.gender ?? "";
      this.addr = data.addr ?? "";
      this.addr2 = data.addr2 ?? "";
      this.lowSpecialty = data.lowSpecialty ?? "없음";
      this.highSpecialty = data.highSpecialty ?? "없음";
      this.orderSpecialty = data.orderSpecialty ?? "없음";
    },
    formatPhone(num) {
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
    clickUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.finish-title {
  width: 200px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #3a3d4d;
}
.finish-value {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #696c80;
}
.finish-guide {
  font-size: 14px;
  font-weight: 500;
  color: #a0a0a0;
  margin-bottom: 24px;
}
</style>
